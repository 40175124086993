<template>
  <el-button type="text" @click="handleShow"
  >click to open the Dialog</el-button
  >

  <el-dialog
    v-model="dialogVisible"
    title="Tips"
    width="40%"
    :before-close="handleClose"
  >
    <span>{{qrTitle}}</span>
    <qrcode-vue :value='qrCodeUrl' :size='200' level='H' />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false"
        >Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import { defineComponent, ref, onBeforeUnmount } from 'vue'
  import { ElMessageBox } from 'element-plus'
  import QrcodeVue from 'qrcode.vue'

  export default defineComponent({
    components: {
      QrcodeVue
    },
    setup() {
      const qrTitle = ref('微信支付')
      const dialogVisible = ref(false)
      const qrCodeUrl = ref('')
      const handleShow = () => {
        dialogVisible.value = true
        qrCodeUrl.value = 'https://qr.alipay.com/bax01015zbetmmc50sd055d1'
        // 开启定时监听
        getOrderstate('10010111')
      }

      const handleClose = (done) => {
        ElMessageBox.confirm('Are you sure to close this dialog?')
          .then(() => {
            done()
          })
          .catch(() => {
            // catch error
          })
      }
      const watchtimes = ref('')
      const getOrderstate = (transe)=>{
        let num = 0
        watchtimes.value = setInterval(()=>{ //创建一个全局的定时器
          num ++
          // let obj = {
          //   orderId:self.orderId.data, //后台用的是订单判断
          // }
          // this.$http.post(this.$api.****.****, this.$qs.stringify(obj))
          // .then(res=>{
          //   if(res.data.data == true){ //判断 如果data.data==true就是订单支付成功
          //     // this.$router.push({
          //     //   path: '/pintuanchenggong', //成功以后的跳转路径
          //     // })
          //     clearInterval(timers) //别忘记关闭定时器，否则会一直调这个接口
          //   }
          // })
          console.log("curr ", num, transe)
          if(num === 10){ //这里是判断num++到500的情况下用户还没有支付则自动关闭定时器和二维码
            dialogVisible.value = false
            clearInterval(watchtimes.value)
          }
        },1500)
      }
      onBeforeUnmount(() => {
        console.log('销毁定时器')
        clearInterval(watchtimes.value)
      })
      return {
        dialogVisible,
        handleShow,
        handleClose,
        qrCodeUrl,
        watchtimes
      }
    },
  })
</script>

<style type="less"  scoped>
  .about {
    .dialog-header {
      height: 200px;
      width: 300px;
      text-align: center;
      margin: 0 auto;
      .amt {
        color: red;
        font-size: 40px
      }
    }
    .qrcode {
      margin: 0 auto;
    }
    .dialog-footer {

    }
  }
</style>
